<template>
  <div ref="projectlist">
    <SEARCH :list="searchArr"></SEARCH>
    <TABS :list="tabsArr"></TABS>
    <TREETABLE :isShowSele="false" :list="tableArr" :data="dataArr"></TREETABLE>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TREETABLE from '@/components/treeTable' // 树表格
import { getUnit } from '@/api/user'
export default {
  name: 'UnitName',
  components: {
    SEARCH,
    TABS,
    TREETABLE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '单位名称',
          type: 'input',
          options: [],
          key: 'unitName',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增单位',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '单位名称',
          width: '1000',
        },
        {
          prop: 'path',
          label: '排序',
          width: '300',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          but: true,
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: '',
        },
      ], // 数据-操作列表
      formData: {
        page: 1,
        limit: 10,
        type: '',
        name: '',
        unit_id: '',
        create_at: '',
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getUnit(this.formData).then((res) => {
        console.log(res, '这是单位管理')
        const { result } = res

        result.forEach((item, index) => {
          item.id = index + 1
          item.buttons = this.butnArr
          item.children = [
            {
              id: Number('11'),
              name: '王小虎',
              path: '16077463176',
              buttons: this.butnArr,
            },
          ]
        })
        this.dataArr = result
        console.log(this.dataArr, '：：---')
      })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
